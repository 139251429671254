@import url(https://fonts.googleapis.com/css?family=Roboto+Mono);

@font-face {
  font-family: 'Salterio-Shadow';
  src: local('Salterio-Shadow'), url('assets/fonts/SalterioShadow.ttf') format('truetype'),
}
@font-face {
  font-family: 'Roboto Mono';
  src: local('Roboto Mono'), url('assets/fonts/RobotoMono-Medium.ttf') format('truetype'),
}
:root {
  /* Base font size */
  /* Set neon color */
  --neon-text-color: rgba(255, 255, 255, 0.171);
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.app-container {
  background-color: #070707;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

a {
  text-decoration: none;
  color:#6f0000;
  transition-duration: 200ms;
}

a:hover{
  transition-duration: 200ms;
  color: #fff;
}

.fixed-background {
  position: fixed;
  background-color: #070707;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 0;
}


.line {
  position: absolute;
}

.line1{
  position: relative;
  rotate: 45deg;
  left: -50%;
  z-index: -1;
}
.line2{
  left: -50%;
  top: -20%;
  rotate: 20deg;
  z-index: -2;
}
.line3{
  left: -50%;
  top: -20%;
  rotate: -60deg;
  z-index: -3;
}
.line4{
  top: 50%;
  left: -20%;
  rotate: 35deg;
  z-index: -4;
}
.content{
  align-items: center;
  justify-content: center;
  z-index: 1;
}
.front-page-container {
  margin: 20%;
}
h1[title='DYLAN CASSELL'] {
  display: flex;
  margin-left: 5%;
  font-size: 100px;
  font-style: italic;
  font-family: "Salterio-Shadow";
  animation: glitch 1s linear infinite;
}
h1[title='DYLAN'], h1[title='CASSELL'] {
  display: none;
}
h3 {
  color:rgba(255, 255, 255, 0.726);
  font-weight: bolder;
  text-align: center;
  font-family: "Roboto Mono", monospace!important;
  -webkit-animation: glow 1s ease-in-out infinite alternate;
  -moz-animation: glow 1s ease-in-out infinite alternate;
  animation: flicker 1.5s infinite alternate;   
}
h3::-moz-selection {
  color: var(--neon-text-color);
}

h3::selection {
  color: var(--neon-text-color);
}

h3:focus {
  outline: none;
}

/* Animate neon flicker */
@keyframes flicker {
    
    0%, 19%, 21%, 23%, 25%, 54%, 56%, 100% {
      
        text-shadow:
            -0.2rem -0.2rem 1rem #fff,
            0.2rem 0.2rem 1rem #fff,
            0 0 2rem var(--neon-text-color),
            0 0 4rem var(--neon-text-color),
            0 0 6rem var(--neon-text-color),
            0 0 8rem var(--neon-text-color),
            0 0 10rem var(--neon-text-color);       
    }
    
    20%, 24%, 55% {        
        text-shadow: none;
    }    
}
@keyframes glitch{
  2%,64%{
    transform: translate(2px,0) skew(0deg);
  }
  4%,60%{
    transform: translate(-2px,0) skew(0deg);
  }
  62%{
    transform: translate(0,0) skew(5deg); 
  }
}

h1:before,
h1:after, h3:before,
h3:after{
  content: attr(title);
  position: absolute;
  left: 0;
}

h1:before, h3:before{
  animation: glitchTop 1s linear infinite;
  clip-path: polygon(0 0, 100% 0, 100% 33%, 0 33%);
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 33%, 0 33%);
}

@keyframes glitchTop{
  2%,64%{
    transform: translate(2px,-2px);
  }
  4%,60%{
    transform: translate(-2px,2px);
  }
  62%{
    transform: translate(13px,-1px) skew(-13deg); 
  }
}

h1:after, h3:after{
  animation: glitchBotom 1.5s linear infinite;
  clip-path: polygon(0 67%, 100% 67%, 100% 100%, 0 100%);
  -webkit-clip-path: polygon(0 67%, 100% 67%, 100% 100%, 0 100%);
}

@keyframes glitchBotom{
  2%,64%{
    transform: translate(-2px,0);
  }
  4%,60%{
    transform: translate(-2px,0);
  }
  62%{
    transform: translate(-22px,5px) skew(21deg); 
  }
}

.scroll-down-icon-container {
  text-align: center;
}
.scrollDownIcon {
  position: relative;
  top: 200px;
  z-index: 999;
  width: 4%;
}

.info-container {
  margin-left: 15%;
  display: flex;
  justify-content: space-between;
  padding: 20px;
  width: 80%;
}

.info-container h2 {
  padding: 10px;
  font-family: "Roboto Mono", monospace!important;
  border-bottom:4px solid #383838;
  width: 120%;
}
.info-body {
  padding-right: 5%;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  flex: 1;
  
}

.picture {
  text-align: center;
  flex: 1;
}

.resume-container {
  display:flex;
  justify-content: space-between;
  width: 80%;
  margin: 5%;
  margin-left: 10%;
  font-family: "Roboto Mono";
  background-color: #6f0000;
  transition-duration: 200ms;
  box-shadow: rgba(255, 255, 255, 0.25) 0px 50px 100px -20px, rgba(156, 156, 156, 0.3) 0px 30px 60px -30px, rgba(172, 172, 172, 0.35) 0px -2px 6px 0px inset;
}

.resume-container:hover {
  cursor: pointer;
  transform: scale(1.1);
  transition-duration: 200ms;
}

.resume-button-text {
  padding: 2%;
  flex: 1;
}
.resume-button-logo{
  text-align: right;
  flex: 1;
}

.logo {
  padding: 5%;
  width: 15%;
}



.contact-form h3{
  color:rgb(255, 255, 255);
  font-size: 24px;
  text-align: center;
  font-family: "Roboto Mono", monospace!important;
  -webkit-animation: none 1s ease-in-out infinite alternate;
  -moz-animation: none 1s ease-in-out infinite alternate;
  animation: none 1.5s infinite alternate;   
}

.contact-form {
  width: 60%;
  margin: 15% auto;
  padding: 20px;
  font-family: "Salterio-Shadow";
  border-top:4px solid #383838;
}

.contact-form h2 {
  font-size: 60px;
  text-align: center;
  margin-bottom: 20px;
}

.contact-form label {
  display: block;
  font-weight: bold;
  margin-bottom: 10px;
}

.contact-form input[type="text"],
.contact-form input[type="email"],
.contact-form textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 3px;
  font-size: 16px;
  color: #333;
}

.contact-form button {
  font-family: "Salterio-Shadow";
  display: block;
  width: 100%;
  padding: 10px;
  background-color: #6f0000;
  border: 1px solid #000000;
  color: #000000;
  border: none;
  border-radius: 5px;
  font-size: 18px;
  cursor: pointer;
  transition-duration: 200ms;
}

.contact-form button:hover {
  transition-duration: 200ms;
  background-color: #ffffff;
}

.contact-form div {
  margin-bottom: 15px;
}
.social-media-icons {
  text-align: center;
}

.social-media-icons .linkedin,
.social-media-icons .github,
.social-media-icons .twitter{
  color:#6f0000;
  transition-duration: 200ms;
  margin: 10px;
  
}

.social-media-icons .linkedin:hover,
.social-media-icons .github:hover,
.social-media-icons .twitter:hover{
  transform: scale(1.2);
  transition-duration: 200ms;
  color: white !important;
}

.copyright-container {
  font-size: 24px;
  font-family: "Roboto Mono";
  text-align: center;
  padding: 25px;
}
.copyright-container span {
  font-style: italic;
  font-family: "Salterio-Shadow";
}

.portfolio-container {
  margin-top: 15%;
  margin-left: 17%;
  display: grid;
  grid-template-columns: repeat(2, 40%);
  grid-gap: 10px;
}

.portfolio-container img {
  width: 600px; 
  height: 500px; 
  object-fit: cover;
  transition: 0.5s ease;
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
}

.portfolio-container img:hover {
  cursor: pointer;
  -webkit-filter: blur(2px);
  transition: 0.5s ease;
  filter: grayscale(0%);
  filter: gray;
  -webkit-filter: grayscale(0%);
  filter: none;
  box-shadow: rgba(255, 255, 255, 0.25) 0px 50px 100px -20px, rgba(156, 156, 156, 0.3) 0px 30px 60px -30px, rgba(172, 172, 172, 0.35) 0px -2px 6px 0px inset;

}

::-webkit-scrollbar{
  width: 15px
}

::-webkit-scrollbar-track {
  background: #181818;
}

::-webkit-scrollbar-thumb {
  border: #00000057 1px solid;
  background: #6f0000;
  border-radius: 5px;
}

@media (max-width: 800px) {
  .fixed-background {
    left: -1400px;
  }
  .front-page-container {
    margin: 0%;
    margin-top: 60%;
    margin-bottom:75%;
    padding: 20px;
  }
  h1[title='DYLAN CASSELL']{
    text-align: center;
    margin-left: 0%;
    font-size: 68px;
  }
  .info-container{
    margin-left: 5%;
    text-align: center;
    width: 80%;
    display: block;
  }
  .info-container h2 {
    padding: 10px;
    font-family: "Roboto Mono", monospace!important;
    border-bottom:4px solid #383838;
    width: 100%;
  }
  .contact-form {
    width: 70%;
    margin: 0% auto;
  }
  .picture {
    width: 110%;
  }

  .contact-form h3 {
    font-size: 14px;
  }

  .portfolio-container {
    margin: 15% 2%;
    grid-template-columns: repeat(1, 50%);
  }

  .portfolio-container img {
    height: 200px;
    width: 200%;
  }

}